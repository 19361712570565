import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";

type Anchor = "left";

const catalogs = [
  { name: "Анімэ", link: "https://anibel.net/anime" },
  { name: "Манга", link: "https://anibel.net/manga" },
  { name: "Кіно і серыялы", link: "https://anibel.net/cinema" },
];

const socials = [
  { name: "YouTube", link: "https://youtube.com/anibel" },
  { name: "Telegram", link: "https://t.me/anibel_by" },
  { name: "Instagram", link: "https://instagram.com/anibelby" },
  { name: "Twitter", link: "https://twitter.com/anibel_by" },
  { name: "VK", link: "https://vk.com/anibel_by" },
];

export default function LeftMenu() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 2 }}
        onClick={toggleDrawer("left", true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Іншыя каталогі
              </ListSubheader>
            }
          >
            {catalogs.map(({ name: text, link }) => (
              <ListItem disablePadding key={text}>
                <ListItemButton component="a" href={link} target="_blank">
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Мы ў сацыяльных сетках
              </ListSubheader>
            }
          >
            {socials.map(({ name: text, link }) => (
              <ListItem disablePadding key={text}>
                <ListItemButton component="a" href={link} target="_blank">
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
