import { Box } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/Header";
import Slider from "./components/Slider";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./core/theme";
import { StoreProvider } from "./core/store/context";
import AddGameForm from "./components/AddGameForm";
import { Games } from "./components/Games";

function App() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <Slider />
          <Box sx={{ mt: 3 }} />
          <Container>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Новыя гульні
            </Typography>
            <Games showNew />
            <Box sx={{ mt: 3 }} />
            <Typography variant="h5" sx={{ mb: 2 }}>
              Усе гульні
            </Typography>
            <Games />
            <Box sx={{ mt: 3 }} />
          </Container>
          <Box
            component="footer"
            sx={{
              py: 2,
              px: 2,
              mt: "auto",
              backgroundColor: "white",
            }}
          >
            <Container>
              <Typography variant="body1">Designed By Wiedy Mi</Typography>
            </Container>
          </Box>
          <AddGameForm />
        </ThemeProvider>
      </StoreProvider>
    </Box>
  );
}

export default App;
