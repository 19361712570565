import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import GCard, { IGCard } from "./Card";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getGames } from "../core/store/firestore";

export const Games = ({ showNew }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [games, setGames] = useState<IGCard[]>([]);

  useEffect(() => {
    getGames((data) => {
      if (showNew) {
        setGames(data.slice(0, 3));
      } else {
        setGames(data);
      }

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: "160px",
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {games.map((game: IGCard, i) => {
          return (
            <Grid key={game.id || i} item xs={12} sm={6} lg={4}>
              <GCard {...game} />
            </Grid>
          );
        })}
      </Grid>
      {games.length > 8 && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" size="large">
            Больш гульняў
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};
