import React, { useContext, useState } from "react";

interface IStore {
  state: object;
  setState: (newState: any) => any;
}

const StoreContext = React.createContext({
  state: {},
  setState: () => ({}),
} as IStore);

export const StoreProvider: React.FC = ({ children }) => {
  const [state, setState] = useState({});
  return (
    <StoreContext.Provider
      value={
        {
          state,
          setState,
        } as IStore
      }
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const context = useContext(StoreContext);

  return [context.state, context.setState] as [any, typeof context.setState];
};
