import { IGCard } from "./../../components/Card";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBcX5giUpRQBaujPoYu0-tTZZ2z_7GisKI",
  authDomain: "anibel-play.firebaseapp.com",
  databaseURL:
    "https://anibel-play-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "anibel-play",
  storageBucket: "anibel-play.appspot.com",
  messagingSenderId: "453226558711",
  appId: "1:453226558711:web:393a5797a4006439b8cff2",
  measurementId: "G-3834ZE6LVG",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export async function getGames(cb: (data: any) => void) {
  const gamesCol = collection(firestore, "games");
  const q = query(gamesCol, where("validated", "==", true));
  const gameSnapshot = await getDocs(q);
  const gameList = gameSnapshot.docs.map((doc) => doc.data());

  if (cb) {
    cb(gameList);
  }

  return gameList;
}

export async function getSlider(cb: (data: any) => void) {
  const col = collection(firestore, "slider");
  const q = query(col);
  const snapshot = await getDocs(q);
  const list = snapshot.docs.map((doc) => doc.data());

  if (cb) {
    cb(list);
  }

  return list;
}

export async function addGame(form: IGCard, cb: (data: any) => void) {
  const gameId = uuid();
  await setDoc(doc(firestore, "games", gameId), {
    ...form,
    id: gameId,
    validated: false,
  });

  if (cb) {
    cb(gameId);
  }
}

export async function searchGames() {}
