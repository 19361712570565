import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useStore } from "../core/store/context";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { addGame } from "../core/store/firestore";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "calc(100% - 32px)", sm: 400 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

interface IForm {
  link: string;
  img: string;
  instructions: string;
  title: string;
  type: "by_user" | "official" | "original" | string;
  [key: string]: string | boolean | undefined;
}

const defaultValues = {
  type: "",
  link: "",
  img: "",
  instructions: "",
  title: "",
} as IForm;

const isNotEmpty = (value = "") => !!value && value.length > 1;

export default function AddGameForm() {
  const [isLoading, setLoading] = useState(false);

  const [form, setForm] = useState(defaultValues);
  const isValid = Object.keys(defaultValues).every((key) =>
    isNotEmpty(form[key] as string)
  );

  const [state, setState] = useStore();
  const handleClose = () =>
    setState((prevState: object) => ({ ...prevState, openGameModal: false }));

  const handleSave = () => {
    setLoading(true);
    addGame(form as any, (gameId: string) => {
      setLoading(false);
      handleClose();
    });
  };

  const generateField = (name: string) => {
    return {
      value: form[name],
      onChange: (e: any) =>
        setForm(
          (prevState) =>
            ({
              ...prevState,
              [name]: e.target.value as string,
            } as IForm)
        ),
    };
  };

  return (
    <div>
      <Modal
        open={state.openGameModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Дадаць гульню ў каталог
            </Typography>
            <Typography id="modal-modal-description">
              Калі ласка, запоўніце форму ніжэй
            </Typography>
          </Stack>
          <Box sx={{ mb: 2, mt: 2 }}>
            <TextField
              label="Назва гульні"
              placeholder="Напішыце назву гульні..."
              variant="outlined"
              fullWidth
              {...generateField("title")}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Спасылка на постар"
              placeholder="Устаўце спасылку на постар..."
              variant="outlined"
              fullWidth
              {...generateField("img")}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Спасылка на беларусізатар"
              placeholder="Устаўце спасылку на беларусізатар..."
              variant="outlined"
              fullWidth
              {...generateField("link")}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              select
              label="Аўтар перакладу"
              value={form.type}
              onChange={(e) => {
                setForm(
                  (prevState) =>
                    ({
                      ...prevState,
                      type: e.target.value,
                    } as IForm)
                );
              }}
            >
              <MenuItem value="official">Афіцыйны пераклад</MenuItem>
              <MenuItem value="original">Арыгінальная гульня</MenuItem>
              <MenuItem value="by_user">
                Аматарскі пераклад (беларусізатар)
              </MenuItem>
            </TextField>
          </Box>
          {form.type === "by_user" && (
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Імя аўтара перакладу"
                placeholder="Напішыце імя аўтара перакладу..."
                variant="outlined"
                fullWidth
                {...generateField("translated_by")}
              />
            </Box>
          )}
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              label="Інструкцыя па ўстаўлёўкі"
              placeholder="Напішыце іструкцыі па ўсталёўкі беларускай мовы..."
              variant="outlined"
              multiline
              minRows={3}
              {...generateField("instructions")}
            />
          </Box>

          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button variant="text" onClick={handleClose}>
              Скасаваць
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              disabled={
                !isValid || (form.type === "by_user" && !form.translated_by)
              }
              onClick={handleSave}
            >
              Дадаць
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
