import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface IGCard {
  title: string;
  instructions: string;
  id: string;
  img: string;
  link: string;
  type: "original" | "official" | string;
  translated_by?: string;
}

export default function GCard({
  title,
  type,
  img,
  link,
  instructions,
  translated_by,
}: IGCard) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ display: "flex", maxHeight: 160 }}>
      <CardMedia
        component="img"
        sx={{ height: 160, width: 120 }}
        image={img}
        alt={title}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" component="div">
            {type === "official" && "Афіцыйны пераклад"}
            {type === "original" && "Арыгінальная гульня"}
            {!["original", "official"].includes(type) &&
              `Пераклад ад ${translated_by}`}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",

            p: 1,
            flexDirection: "column",
          }}
        >
          <Button
            onClick={handleClickOpen}
            sx={{ width: "100%", justifyContent: "flex-start" }}
            size="small"
            color="info"
            startIcon={<HelpOutlineOutlinedIcon />}
          >
            як усталяваць
          </Button>
          <Button
            sx={{ width: "100%", justifyContent: "flex-start" }}
            size="small"
            color="primary"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={() => {
              window.open(link, "_blank");
            }}
          >
            Спампаваць
          </Button>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Як усталяваць беларускую мову ў {title}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ whiteSpace: "pre-line" }}
          >
            {instructions}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Зразумела
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
